exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linser-js": () => import("./../../../src/pages/linser.js" /* webpackChunkName: "component---src-pages-linser-js" */),
  "component---src-pages-merker-js": () => import("./../../../src/pages/merker.js" /* webpackChunkName: "component---src-pages-merker-js" */),
  "component---src-pages-personvernpolicy-js": () => import("./../../../src/pages/personvernpolicy.js" /* webpackChunkName: "component---src-pages-personvernpolicy-js" */),
  "component---src-pages-timebestilling-js": () => import("./../../../src/pages/timebestilling.js" /* webpackChunkName: "component---src-pages-timebestilling-js" */),
  "component---src-templates-product-list-page-js": () => import("./../../../src/templates/productListPage.js" /* webpackChunkName: "component---src-templates-product-list-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */)
}

